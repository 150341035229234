<script
    lang="ts"
    setup
>
    import type { AppMediaViewerContext } from '~/ts/types/app'

    const context = ref<AppMediaViewerContext>()

    const loaded = ref<boolean>(false)

    const open = (ctx: AppMediaViewerContext): void => {
        context.value = ctx
    }

    const busUnsubscribe = useEventBus().on<AppMediaViewerContext>(BUS_EVENT_APP_OPEN_MEDIA_VIEWER, open)

    const close = (): void => {
        loaded.value = false

        context.value.onClosed?.()

        context.value = undefined
    }

    onBeforeUnmount(() => busUnsubscribe())
</script>

<template>
    <Transition name="fade-transition">
        <div
            v-if="context"
            :class="$style['app-media-viewer']"
            @click.self="close()"
        >
            <div
                :class="$style['app-media-viewer__container']"
                @click.self="close()"
            >
                <div
                    key="close"
                    :class="$style['app-media-viewer__container__close']"
                    @click="close()"
                >
                    <AppIconClose size="20" />
                </div>

                <div
                    v-if="!loaded"
                    key="loader"
                    :class="$style['app-media-viewer__container__loader']"
                >
                    <AppIconLoading
                        size="68"
                        :animate="true"
                    />
                </div>

                <img
                    key="image"
                    :src="context.src"
                    alt="Image"
                    draggable="false"
                    :style="{ opacity: loaded ? undefined : '0' }"
                    @load="loaded = true"
                />

                <div
                    v-if="context.text"
                    key="text"
                    :class="$style['app-media-viewer__container__text']"
                >
                    {{ context.text }}
                </div>
            </div>
        </div>
    </Transition>
</template>

<style
    lang="sass"
    module
    scoped
>
    .app-media-viewer
        z-index: 3
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        background: rgba(0, 0, 0, 0.6)
        cursor: pointer

        &__container
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center

            &__close
                position: fixed
                display: flex
                align-items: center
                justify-content: center
                top: 8px
                right: 8px
                width: 56px
                height: 56px
                border-radius: 50%
                cursor: pointer

                &:deep(> svg > path)
                    fill: #e0e2ea
                    transition: color var(--transition-default-duration-with-ease)
                    will-change: color

                &:hover:deep(> svg > path)
                    fill: #fff

                &:active:deep(> svg > path)
                    fill: #8a8f9e

            &__loader
                position: fixed
                display: flex
                justify-content: center
                align-items: center
                top: 0
                left: 0
                width: 100%
                height: 100%

            > img
                flex: 1
                max-width: 80vw
                max-height: 80vh
                width: min(600px, 100%)
                height: min(600px, 100%)
                transition: opacity var(--transition-default-duration-with-ease)
                cursor: default

            &__text
                z-index: 1
                opacity: 0.7
                overflow: auto
                position: fixed
                bottom: 42px
                max-width: min(60vw, 800px)
                max-height: 56px
                padding: 2px 6px
                line-height: 130%
                font-size: 16px
                font-weight: 400
                background: rgba(0, 0, 0, 0.1)
                color: #fff
                border-radius: 4px
                transition: opacity var(--transition-default-duration-with-ease), max-height var(--transition-default-duration-with-ease), background var(--transition-default-duration-with-ease)
                cursor: default

                &:hover
                    max-height: 80vh
                    opacity: 1
                    background: rgba(0, 0, 0, 0.7)
</style>
