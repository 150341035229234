<script
    lang="ts"
    setup
>
    import type { RouteLocationNamedRaw } from 'vue-router'
    import { useUserStore } from '~/stores/user'
    import { useChatStore } from '~/stores/chat'

    type Props = {
        inNavbar?: boolean
    }

    type NavigationItem = {
        to: RouteLocationNamedRaw
        iconComponent?: ReturnType<typeof defineComponent>
        tooltipText?: string
        showNotificationBadge?: boolean
        isAccountActivator?: boolean
    }

    const props = defineProps<Props>()

    const userStore = useUserStore()
    const chatStore = useChatStore()
    const { maxTablet, maxDesktop } = useWindowSize()

    const { t } = useLang()

    const accountActivatorNotificationBadge = computed<boolean>(() => {
        for (const projectId in chatStore.isThereChatWithUnreadMessagesInOtherProjectsByProjectId) {
            if (chatStore.isThereChatWithUnreadMessagesInOtherProjectsByProjectId[projectId]) {
                return true
            }
        }

        return false
    })

    const accountActivatorText = computed<string>(() => userStore.currentProject?.name?.[0])

    const navigationItems = computed<NavigationItem[]>(() => {
        const items: NavigationItem[] = []

        const itemSettings = {
            iconComponent: resolveComponent('AppIconCog'),
            tooltipText: t('settings'),
            to: { name: maxDesktop.value ? 'p-pid-setting' : 'p-pid-setting-account' },
        }

        const itemAccount = {
            isAccountActivator: true,
            showNotificationBadge: accountActivatorNotificationBadge.value,
            to: { name: 'p-pid-account' },
        }

        if (userStore.isOperatorEnabled) {
            items.push(
                ...[
                    {
                        iconComponent: resolveComponent('AppIconChat'),
                        tooltipText: t('chat'),
                        showNotificationBadge: chatStore.isThereChatWithUnreadMessages,
                        to: { name: 'p-pid-chat' },
                    },
                    {
                        iconComponent: resolveComponent('AppIconBooks'),
                        tooltipText: t('knowledge-base'),
                        to: { name: 'p-pid-knowledge-base' },
                    },
                    itemSettings,
                    {
                        iconComponent: resolveComponent('AppIconPro'),
                        tooltipText: t('subscription'),
                        to: { name: 'p-pid-billing' },
                    },
                ],
            )
        } else {
            items.push(
                ...[
                    {
                        iconComponent: resolveComponent('AppIconChatLock'),
                        tooltipText: t('chat'),
                        to: { name: 'p-pid-inactive-operator' },
                    },
                    itemSettings,
                ],
            )
        }

        if (maxTablet.value) {
            items.push(itemAccount)
        }

        return items
    })

    const route = useRoute()

    const isButtonActive = (to: RouteLocationNamedRaw): boolean => {
        switch (to.name) {
            case 'p-pid-chat':
                return String(route.name).startsWith('p-pid-chat')
            case 'p-pid-setting':
            case 'p-pid-setting-account':
                return String(route.name).startsWith('p-pid-setting')
            default:
                return route.name === to.name
        }
    }
</script>

<template>
    <AppTooltip
        v-for="item in navigationItems"
        :key="item.to.name"
        direction="right"
        no-wrap
        :disabled="props.inNavbar"
    >
        <template #activator="{ open, close }">
            <AppLink
                wrapper
                :to="item.to"
                @mouseenter.passive="open"
                @mouseleave.passive="close"
            >
                <AppButtonIcon
                    v-slot="{ color }"
                    :active="isButtonActive(item.to)"
                    class="relative"
                    :style="{ padding: item.to.name === 'p-pid-billing' ? '7px' : undefined }"
                >
                    <AppAvatar
                        v-if="item.isAccountActivator"
                        key="account-avatar"
                        size="32"
                        class="cursor-pointer"
                    >
                        <div class="text-[16px] font-[700] uppercase">
                            {{ accountActivatorText }}
                        </div>
                    </AppAvatar>

                    <!-- eslint-disable @stylistic/max-len -->
                    <component
                        :is="item.iconComponent"
                        v-else
                        key="icon"
                        :color="item.to.name === 'p-pid-billing' && userStore.activeSubscription ? 'var(--color-primary)' : color"
                        :text-color="item.to.name === 'p-pid-billing' && userStore.activeSubscription ? '#000' : undefined"
                        :size="item.to.name === 'p-pid-billing' ? 34 : undefined"
                    />
                    <!-- eslint-enable @stylistic/max-len -->

                    <Transition name="scale-transition">
                        <AppNotificationBadge
                            v-if="item.showNotificationBadge"
                            key="badge"
                            class="top-1.5 right-1.5"
                        />
                    </Transition>
                </AppButtonIcon>
            </AppLink>
        </template>

        {{ item.tooltipText }}
    </AppTooltip>
</template>
