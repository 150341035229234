<script
    lang="ts"
    setup
>
    import { useAppStore } from '~/stores/app'
    import { AppCustomSystemAlertTypeEnum } from '~/ts/enums/app'

    type Action = 'confirm-email' | 'activate-operators' | 'update-subscription' | 'enable-notifications'

    const appStore = useAppStore()

    const webNotify = useWebNotification()

    const isTypeEnableNotifications = computed<boolean>(() => {
        return appStore.systemAlert.type === AppCustomSystemAlertTypeEnum.AppEnableNotifications
    })

    const action = computed<Action | undefined>(() => {
        if (appStore.systemAlert.type === 'user:need_confirm_email') {
            return 'confirm-email'
        }

        if (appStore.systemAlert.type === 'project:no_active_operators') {
            return 'activate-operators'
        }

        if (appStore.systemAlert.type.startsWith('project:subscription_expired_')) {
            return 'update-subscription'
        }

        if (isTypeEnableNotifications.value && (webNotify.permission.value === 'default')) {
            return 'enable-notifications'
        }

        return undefined
    })

    const { t } = useLang()

    const actionText = computed<string>(() => {
        if (isTypeEnableNotifications.value) {
            return t('turn-on')
        }

        switch (action.value) {
            case 'confirm-email':
            case 'activate-operators':
                return t('configure')
            case 'update-subscription':
                return t('update')
            default:
                return '-'
        }
    })

    const iconComponent = computed<ReturnType<typeof defineComponent>>(() => {
        switch (action.value) {
            case 'confirm-email':
            case 'activate-operators':
                return resolveComponent('AppIconAlertOutline')
            case 'update-subscription':
                return resolveComponent('AppIconClockOutline')
            default:
                return resolveComponent('AppIconInfo')
        }
    })

    const alertStyle = computed(() => appStore.systemAlert.content?.style)

    const textColor = computed<string>(() => {
        if (isTypeEnableNotifications.value) {
            return '#000'
        }

        return (alertStyle.value === 'danger') ? '#bc0909' : (alertStyle.value === 'warning') ? '#c76c00' : '#0e3c7a'
    })

    const bgColor = computed<string>(() => appStore.bannerBgColor)

    const buttonColors = computed(() => {
        if (isTypeEnableNotifications.value) {
            return {
                inactiveColor: '#fff',
                activeColor: '#fff',
                inactiveBgColor: '#000',
                hoverBgColor: 'rgba(0,0,0,0.8)',
                activeBgColor: 'rgba(0,0,0,0.7)',
            }
        }

        return {
            inactiveColor: bgColor.value,
            activeColor: bgColor.value,
            inactiveBgColor: (alertStyle.value === 'danger') ? '#af0909ff' : (alertStyle.value === 'warning') ? '#bb6601ff' : '#0d3872ff',
            hoverBgColor: (alertStyle.value === 'danger') ? '#9f0808ff' : (alertStyle.value === 'warning') ? '#ad5e01ff' : '#0c3164ff',
            activeBgColor: (alertStyle.value === 'danger') ? '#860606ff' : (alertStyle.value === 'warning') ? '#9b5501ff' : '#09264fff',
        }
    })

    const containerStyle = computed<string>(() => {
        return `
            --banner-color:${ textColor.value };
            --banner-bg-color:${ bgColor.value };
        `
    })

    const containerClass = computed<string>(() => {
        return `
            relative
            flex
            items-center
            justify-center
            gap-4
            w-full
            ${ isTypeEnableNotifications.value ? 'p-0' : 'p-2' }
            max-tablet:py-3
            bg-[var(--banner-bg-color)]
            text-[var(--banner-color)]
        `
    })

    const triggerAction = (): void => {
        switch (action.value) {
            case 'confirm-email':
                navigateTo({ name: 'p-pid-setting-account' })
                break
            case 'activate-operators':
                navigateTo({ name: 'p-pid-setting-operator' })
                break
            case 'update-subscription':
                navigateTo({ name: 'p-pid-billing' })
                break
            case 'enable-notifications':
                webNotify.ensurePermissions()
                break
        }
    }

    const close = () => appStore.removeCurrentSystemAlert()
</script>

<template>
    <div
        :class="containerClass"
        :style="containerStyle"
    >
        <div class="flex items-center gap-2">
            <AppIconBannerEnableNotifications
                v-if="isTypeEnableNotifications"
                key="notify-icon"
            />

            <component
                :is="iconComponent"
                v-else
                key="icon"
                size="18"
                :color="textColor"
            />

            <div
                key="message"
                class="overflow-hidden line-clamp-2 max-tablet:text-[14px]"
                :title="appStore.systemAlert.content?.message"
            >
                <template v-if="isTypeEnableNotifications">
                    <span class="font-medium">
                        {{ $t('enable-notifications-1') }}
                    </span>

                    {{ $t('enable-notifications-2') }}
                </template>

                <template v-else>
                    {{ appStore.systemAlert.content?.message }}
                </template>
            </div>
        </div>

        <div
            key="actions"
            class="flex items-center gap-2"
        >
            <AppButton
                v-if="action"
                key="action"
                small
                v-bind="buttonColors"
                @click="triggerAction()"
            >
                {{ actionText }}
            </AppButton>

            <AppButton
                v-if="appStore.systemAlert.can_close"
                key="close"
                underlined
                :inactive-color="buttonColors.inactiveBgColor"
                :active-color="buttonColors.activeBgColor"
                small
                @click="close()"
            >
                {{ $t('close') }}
            </AppButton>
        </div>

        <div
            key="rounding"
            class="absolute top-[100%] left-0 w-[inherit] h-[16px] bg-[inherit]"
        ></div>
    </div>
</template>
